<template lang="pug">
  jumbotron(:disabled="disabled")
    template(v-slot:content)
      appDatepicker(
        :value="$route.query[PANEL_STATS_ARGS.COURSE_DATE_FROM]"
        :class="{'active': dateFrom}"
        label="Course From"
        @input="setFilter(PANEL_STATS_ARGS.COURSE_DATE_FROM, $event)"
        @change="setFilter(PANEL_STATS_ARGS.COURSE_DATE_FROM, $event)"
        @clear="resetFilter(PANEL_STATS_ARGS.COURSE_DATE_FROM, $event)"
        no-title
        calendar
        :max="getToday"
      )

      appDatepicker(
        :value="$route.query[PANEL_STATS_ARGS.COURSE_DATE_TO]"
        :class="{'active': dateTo}"
        label="Course To"
        @input="setFilter(PANEL_STATS_ARGS.COURSE_DATE_TO, $event)"
        @change="setFilter(PANEL_STATS_ARGS.COURSE_DATE_TO, $event)"
        @clear="resetFilter(PANEL_STATS_ARGS.COURSE_DATE_TO, $event)"
        no-title
        calendar
        :max="getToday"
      )

      base-select(
        :options="crmCourseLocation"
        :value="+$route.query[PANEL_STATS_ARGS.COURSE_CITY_ID]"
        filter
        searchable
        @input="setFilter(PANEL_STATS_ARGS.COURSE_CITY_ID, $event)"
        placeholder="Course Location"
        slug-key='ID'
        slug-value='name'
        show-close-btn
      ).mr-3

      base-select(
        :options="crmCourseLeaders"
        :value="+$route.query[PANEL_STATS_ARGS.COURSE_LEADER_ID]"
        filter
        searchable
        @input="setFilter(PANEL_STATS_ARGS.COURSE_LEADER_ID, $event)"
        placeholder="Course Leader"
        slug-key='id'
        slug-value='name'
        show-close-btn
      ).mr-3

      appSelect(
        v-if="isByLeaderOnCourseTab"
        :items="sessionsList"
        label="Course Session"
        item-text="name"
        item-value="id"
        multiple
        :value="getSelectedSessions"
        @change="setFilter(PANEL_STATS_ARGS.COURSE_SESSION, $event, true)"
        @clear="resetSessionsFilter(PANEL_STATS_ARGS.COURSE_SESSION, $event)"
      )

      appSelect(
        v-if="isByLeaderOnCourseTab || isByLeadersTab"
        :items="answersList"
        label="By answers"
        item-text="label"
        item-value="id"
        multiple
        :value="getSelectedAnswers"
        @change="setFilter(PANEL_STATS_ARGS.ANSWERS, $event, true)"
        @clear="resetSessionsFilter(PANEL_STATS_ARGS.ANSWERS, $event)"
      )

      appSelect(
        v-if="isByLeaderOnCourseTab || isByLeadersTab"
        :items="getHazardQuestions"
        label="By Gevaarherkenning"
        item-text="label"
        item-value="id"
        multiple
        :value="getHazardAnswers"
        @change="setFilter(PANEL_STATS_ARGS.HAZARD, $event, true)"
        @clear="resetSessionsFilter(PANEL_STATS_ARGS.HAZARD, $event)"
      )

      appSelect(
        v-if="isByLeaderOnCourseTab || isByLeadersTab"
        :items="getKnowledgeQuestions"
        label="By Kennis"
        item-text="label"
        item-value="id"
        multiple
        :value="getKnowledgeAnswers"
        @change="setFilter(PANEL_STATS_ARGS.KNOWLEDGE, $event, true)"
        @clear="resetSessionsFilter(PANEL_STATS_ARGS.KNOWLEDGE, $event)"
      )

      appSelect(
        v-if="isByLeaderOnCourseTab || isByLeadersTab"
        :items="getInsightQuestions"
        label="By Inzicht"
        item-text="label"
        item-value="id"
        multiple
        :value="getInsightAnswers"
        @change="setFilter(PANEL_STATS_ARGS.INSIGHT, $event, true)"
        @clear="resetSessionsFilter(PANEL_STATS_ARGS.INSIGHT, $event)"
      )

      appSelect(
        v-if="isByLeaderOnCourseTab || isByLeadersTab"
        :items="getKnowledgeInsightQuestions"
        label="By Kennis + Inzicht"
        item-text="label"
        item-value="id"
        multiple
        :value="getKnowledgeInsightAnswers"
        @change="setFilter(PANEL_STATS_ARGS.KNOWLEDGE_INSIGHT, $event, true)"
        @clear="resetSessionsFilter(PANEL_STATS_ARGS.KNOWLEDGE_INSIGHT, $event)"
      )
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { COURSE_LEADERS_TABS_LIST, LEADERS_QUERY_TAB, PANEL_STATS_ARGS, CORRECT_ANSWERS_COUNTS } from '../core/courseLeadersConst'
import errorsMixin from '@/mixins/errors.mixin'
import paginationMixin from '@/mixins/pagination.mixin'

import jumbotron from '@/components/global/fields/Jumbotron.vue'
import appSelect from '@/components/global/fields/appSelect.vue'
import baseSelect from '@/components/global/actions/BaseSelect.vue';
import appDatepicker from '@/components/global/fields/appDatepicker'
import { getMultipleQueryValueWithNumber } from '@/util'

export default {
  name: 'CourseLeadersFiltersPanel',

  mixins: [errorsMixin, paginationMixin],

  props: {
    disabled: {type: Boolean, default: false}
  },

  computed: {
    ...mapGetters({
      crmCourseLocation: 'crmCourseCities/items',
      crmCourseLeaders: 'crmCourseLeaders/leadersList',
      sessionsList: 'sessions/items',
      answersList: 'crmExamAnswers/items'
    }),

    dateFrom() {
      if (this.$route.query[PANEL_STATS_ARGS.COURSE_DATE_FROM]) return this.$route.query[PANEL_STATS_ARGS.COURSE_DATE_FROM]
      else return ''
    },

    dateTo() {
      if (this.$route.query[PANEL_STATS_ARGS.COURSE_DATE_TO]) return this.$route.query[PANEL_STATS_ARGS.COURSE_DATE_TO]
      else return ''
    },

    isByLeaderOnCourseTab() {
      return this.$route.query[LEADERS_QUERY_TAB] === COURSE_LEADERS_TABS_LIST.BY_LEADER_ON_COURSE
    },

    isByLeadersTab() {
      return this.$route.query[LEADERS_QUERY_TAB] === COURSE_LEADERS_TABS_LIST.BY_LEADERS
    },

    getToday() {
      return this.$dates().format('YYYY-MM-DD')
    },

    getSelectedSessions() {
      if (!this.$route.query[PANEL_STATS_ARGS.COURSE_SESSION]) return false
      return getMultipleQueryValueWithNumber(this.$route.query[PANEL_STATS_ARGS.COURSE_SESSION])
    },

    getSelectedAnswers() {
      if (!this.$route.query[PANEL_STATS_ARGS.ANSWERS]) return false
      return getMultipleQueryValueWithNumber(this.$route.query[PANEL_STATS_ARGS.ANSWERS])
    },

    getHazardAnswers() {
      if (!this.$route.query[PANEL_STATS_ARGS.HAZARD]) return false
      return getMultipleQueryValueWithNumber(this.$route.query[PANEL_STATS_ARGS.HAZARD])
    },

    getKnowledgeAnswers() {
      if (!this.$route.query[PANEL_STATS_ARGS.KNOWLEDGE]) return false
      return getMultipleQueryValueWithNumber(this.$route.query[PANEL_STATS_ARGS.KNOWLEDGE])
    },

    getInsightAnswers() {
      if (!this.$route.query[PANEL_STATS_ARGS.INSIGHT]) return false
      return getMultipleQueryValueWithNumber(this.$route.query[PANEL_STATS_ARGS.INSIGHT])
    },

    getKnowledgeInsightAnswers() {
      if (!this.$route.query[PANEL_STATS_ARGS.KNOWLEDGE_INSIGHT]) return false
      return getMultipleQueryValueWithNumber(this.$route.query[PANEL_STATS_ARGS.KNOWLEDGE_INSIGHT])
    },

    getHazardQuestions() {
      return this.answersList.filter(item => item.id <= CORRECT_ANSWERS_COUNTS.HAZARD)
    },

    getKnowledgeQuestions() {
      return this.answersList.filter(item => item.id <= CORRECT_ANSWERS_COUNTS.KNOWLEDGE)
    },

    getInsightQuestions() {
      return this.answersList.filter(item => item.id <= CORRECT_ANSWERS_COUNTS.INSIGHT)
    },

    getKnowledgeInsightQuestions() {
      return this.answersList.filter(item => item.id <= CORRECT_ANSWERS_COUNTS.KNOWLEDGE_INSIGHT)
    }
  },

  data: () => ({
    PANEL_STATS_ARGS
  }),

  created() {
    this.loadSessionsList({})
    this.loadExamAnswersList({length: CORRECT_ANSWERS_COUNTS.sum()})
    this.loadCourseLeadersList({cache: true})
  },

  methods: {
    ...mapActions({
      loadSessionsList: 'sessions/list',
      loadExamAnswersList: 'crmExamAnswers/list',
      loadCourseLeadersList: 'crmCourseLeaders/leadersList'
    }),

    async setFilter(params, value, multiple = false) {
      if (this.$route.query[params] == value) return
      if ((!value && !multiple) || (!value.length && multiple)) return this.resetFilter(params)
      if (this.loading) return

      try {
        this.loading = true
        await this.setQuery({[params]: value});
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    resetFilter(params) {
      if (this.loading) return
      if (!this.$route.query[params]) return
      this.setQuery({[params]: undefined})
    },

    resetSessionsFilter(params) {
      if (this.loading) return
      if (!this.$route.query[params]) return
      this.setQuery({[params]: undefined})
    },

    async setQuery(query) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ...query}})
      this.$emit('change:filter')
    }
  },

  components: {
    jumbotron,
    baseSelect,
    appSelect,
    appDatepicker
  }
}
</script>

<style lang="scss" scoped>
.app-v-select {
  margin-bottom: 5px;
}
</style>
