<template lang="pug">
  .app-v-select(:class="{'active': isActive, 'app-v-select--disabled': disabled}")
    v-select(
      :clearable="clearable"
      hide-details
      :items="items"
      :value="value"
      :loading="loading"
      :disabled="disabled"
      :label="label"
      :item-text="itemText",
      :item-value="itemValue"
      :multiple="multiple"
      :menu-props="{contentClass: 'menu-with-scroll-visible select-menu'}"
      @select="$emit('select', $event)"
      @input="$emit('change', $event)"
      @mouseup="closeSelectIfOpen($refs.select, {enabled: closeOnInputClick})"
      ref="select"
    ).app-v-select__wrapper
      template(v-slot:prepend-item v-if="showCloseMenuBtn")
        select-close-btn(:color="'#272c32'" @click.native="closeSelect($refs.select)")

      template(v-slot:selection="{ item, index }" v-if="multiple")
        span.selection(v-if='index === 0') {{ item[itemText] }}
        span.selection-length.grey--text(v-if='index === 1 && value.length ') (+{{ value.length - 1 }} others)
        span.selection-length.grey--text(v-if='index === 1 && !value.length') (others)

    img(
      v-if="showClearBtn"
      src="@/assets/img/ui/close.svg"
      @click="$emit('clear', $event)"
      :class="{'active': isActive}"
    ).close-icon
</template>

<script>
import AutocompleteMixin from "@/app/admin/mixins/autocomplete.mixin"

export default {
  name: 'appSelect',

  mixins: [AutocompleteMixin],

  props: {
    items: {
      type: Array,
    },
    value: {
      required: true
    },
    field: {
      type: String
    },
    label: String,
    itemText: String,
    itemValue: String,
    multiple: Boolean,
    clearable: Boolean,
    loading: Boolean,
    disabled: Boolean,
    showCloseMenuBtn: {
      default: true,
      type: Boolean
    },
    showClearBtn: {
      default: true,
      type: Boolean
    },
    closeOnInputClick: {
      default: true,
      type: Boolean
    },
    small: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    isActive() {
      return !!this.value
    }
  },

  components: {
    selectCloseBtn: () => import('@/app/admin/components/SelectCloseBtn.vue'),
  }
}
</script>

<style lang="scss">
.app-v-select {
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  width: 100%;
  min-width: 200px;
  max-width: 200px;
  display: flex;
  border-radius: 4px;
  background-color: #f5f7f9;
  cursor: pointer;
  margin-right: 15px;
  transition: 0.15s ease-in-out;

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
    transition: 0.15s ease-in-out;
  }
}

.app-v-select__wrapper {
  padding: 0;
  margin: 0;

  .v-input__slot {
    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }

  .v-select__slot {
    .v-select__selections {
      padding: 0 5px;

      div {
        transition: all 0.3s ease-in-out;
        color: #272c32 !important;
      }

      input {
        padding: 0;
      }
    }

    .v-label {
      color: $placeholder-color !important;
      transition: all 0.3s ease-in-out;

      &.v-label--active {
        transition: all 0.3s ease-in-out;
        color: #272c32 !important;
      }
    }
  }


  .v-application .primary--text {
    color: $placeholder-color !important;
  }

  .v-icon.v-icon--svg.primary--text {
    color: $placeholder-color !important;
  }

  .v-select__selection, .v-label {
    margin-left: 10px;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: $placeholder-color;
  }

  .v-list-item__title {
    font-size: 12px;
    color: #272c32;
  }

  .v-list-item {
    display: block;
    position: relative;
    font-size: 14px;
    background-color: #fff;
    padding: 0;
    min-height: 40px;

    .v-list-item__content {
      padding: 10px 15px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .v-input--is-label-active {
    .v-select__selection, .v-label {
      font-weight: bold;
      color: #272c32;
    }
  }

  .v-input__append-inner {
    margin-top: 0;
  }
}

.select-menu {
  .v-list {
    padding: 0;
  }

  .v-list-item {
    min-height: 36px;

    &:hover {
      background-color: rgba($placeholder-color, 0.2);
    }

    .v-list-item__content {
      padding: 11px 0;

      .v-list-item__title {
        font-family: $font;
        line-height: normal;
        font-size: 16px;
      }
    }

    &.v-list-item--active {
      color: #272c32 !important;
      caret-color: #272c32 !important;
      background-color: rgba($placeholder-color, 0.2);
    }
  }
}

.selection {
  font-size: 13px;
  line-height: normal;
  padding-left: 5px;
  max-width: 110px;
  //
  white-space: nowrap;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
}

.selection-length {
  font-size: 10px;
  line-height: normal;
  padding-left: 3px;
}

.close-icon {
  display: none;
  width: 26px;
  height: 26px;
  position: absolute;
  top: -10px;
  left: auto;
  right: -10px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;

  &.active {
    display: block;
    transition: opacity 0.3s ease-in-out;
  }
}
</style>
