<template lang="pug">
  .tab-by-leader-on-course
    v-data-table(v-show="false" :headers="headers")
    app-table(
      :show-select="false"
      :headers="headers"
      :list="statsByLeaderOnCourse"
      :loading="loading"
      hide-default-header
      hide-default-footer
      :sort-by.sync="sortModel.by"
      :sort-desc.sync="sortModel.desc"
      @update:sort-desc="updateSort"
      class="app-table-new v-data-table theme--light"
    )
      template(v-slot:item="{item, index}")
        item(:item="item" :index="index" :key="index" :total="statsTotal")
        total-item(v-if="index === statsByLeaderOnCourse.length - 1" :total="statsTotal")
      template(v-slot:footer)
        .pagination(v-if="pagination")
          .pagination__more
            load-more-button(:loading="loading" :pagination="pagination" @loadMore="loadMore")

    portal(v-if="byLeaderOnCourseTab" to="course-leaders-paginator")
      .d-flex
        pagination(:pagination="pagination" :loading="loading" @change="loadData")
</template>

<script>
import baseTabMixin from "@/app/admin/modules/course_leaders/core/mixins/baseTabMixin";
import { BY_LEADERS_ON_COURSE_TAB_HEADERS } from '../../core/courseLeadersConst';
import { DEFAULT_COUNT_LIST } from '@/util/const';
import errorsMixin from '@/mixins/errors.mixin';

export default {
  inject: ['svc'],

  mixins: [errorsMixin, baseTabMixin],

  data: () => ({
    DEFAULT_COUNT_LIST,
    headers: BY_LEADERS_ON_COURSE_TAB_HEADERS
  }),

  computed: {
    disabledLoadingMore() {
      if (!this.pagination) return true
      if (this.loading) return true
      if (!this.pagination.next) return true
      return false;
    },

    byLeaderOnCourseTab() {
      return this.$route.query.tab === this.COURSE_LEADERS_TABS_LIST.BY_LEADER_ON_COURSE
    }
  },

  methods: {
    async load() {
      await this.setDefaultPagination()
      await this.loadData()
    },

    async updateSort() {
      await this.updateSorting()
      await this.loadStatsListByLeaderOnCourse({})
    },

    async updateFilters() {
      await this.loadData()
    },

    async loadData() {
      await this.loadStatsListByLeaderOnCourse({})
    },

    async changeItemsPerPageCount(payload) {
      await this.updatePageSize(payload)
      await this.loadData()
    },

    async loadMore() {
      if (!this.pagination) return
      await this.onLoadMoreClick()
      await this.loadStatsListByLeaderOnCourse({more: true})
    }
  },

  components: {
    appTable: () => import('@/components/global/GroupedTable.vue'),
    item: () => import('./TableItem.vue'),
    totalItem: () => import('./ByLeadersTotalItem.vue'),
    pagination: () => import('@/components/widget/PaginationWidget.vue'),
    loadMoreButton: () => import('@/components/widget/LoadMoreButton.vue')
  }
}
</script>

<style lang="scss">
.tab-by-leader-on-course {
  min-height: 100vh;
  padding-bottom: 48px;

  table {
    border-collapse: collapse;
    margin-bottom: 10px;
  }
}

.col--category {
  overflow: hidden;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.loader-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 100px;
}
</style>
