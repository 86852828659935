import {getMultipleQueryValue} from '@/util'
import {SORT_ARGS_QUERY} from './courseLeadersConst'

export default class CourseLeadersSortModel {
  constructor(query) {
    this.by = []
    this.desc = []
    this.ordering = null
    this._apiAttributes = {
      leader__name: 'leader__name',
      course__city__name: 'course__city__name',
      course__date: 'course__date',
      course_count: 'course_count',
      presentie: 'presentie',
      absentie: 'absentie',
      failed: 'failed',
      passed: 'passed',
      zero_mistakes: 'zero_mistakes',
      success: 'success',
      rating: 'rating',
      total_mistakes: 'total_mistakes',
      mistakes_per_student: 'mistakes_per_student'
    }
    this._customSorting = {
      course__city__name: (ordering) => this.setCourseCityAndDateSorting(ordering)
    }
    this._orderingFields = {
      leader__name: 'leader__name',
      course__city__name: 'course__city__name',
      course__date: 'course__date',
      course_count: 'course_count',
      presentie: 'presentie',
      absentie: 'absentie',
      failed: 'failed',
      passed: 'passed',
      zero_mistakes: 'zero_mistakes',
      success: 'success',
      rating: 'rating',
      total_mistakes: 'total_mistakes',
      mistakes_per_student: 'mistakes_per_student'
    }
    this._initByQuery(query)
  }

  _initByQuery(query) {
    for (let sortField in this._apiAttributes) {
      if (this._apiAttributes[sortField] in query) {
        this.by.push(sortField)
        this.desc.push(query[this._apiAttributes[sortField]])
      }
    }
    if (query[SORT_ARGS_QUERY]) this._initOrdering(query[SORT_ARGS_QUERY])
    this._serializeQueryBoolean()
  }

  _initOrdering(query) {
    let val = getMultipleQueryValue(query)
    val.forEach(field => {
      let orderingName = field.replace('-', '')
      if (!(orderingName in this._orderingFields)) return

      let fieldVal = field === '-' ? fieldVal = true : fieldVal = false
      this.by.push(this._orderingFields[orderingName])
      this.desc.push(fieldVal)
    })
  }

  _serializeQueryBoolean() {
    if (!this.desc.length) return
    this.desc = this.desc.map(value => {
      if (value === 'false') value = false
      if (value === 'true') value = true
      return value
    })
  }

  getApiData(query) {
    let data = {}

    this._setOrdering(data, query)

    return data
  }

  _getQueueApiData() {
    let index = this.by.findIndex(field => field === 'inQueue')
    if (index < 0) return
    return this._transformQueryBoolean(this.desc[index])
  }

  _transformQueryBoolean(val) {
    if (val === 'false') return false
    if (val === 'true') return true
    return val
  }

  _getFailedApiData() {
    let index = this.by.findIndex(field => field === 'failed')
    if (index < 0) return
    return this._transformQueryBoolean(this.desc[index])
  }

  _getOnHoldApiData() {
    let index = this.by.findIndex(field => field === 'onHold')
    if (index < 0) return
    return this._transformQueryBoolean(this.desc[index])
  }

  _getOrderingApiData() {
    let index = this.by.findIndex(field => field === 'ordering')
    if (index < 0) return
    return this._transformQueryBoolean(this.desc[index])
  }

  _setOrdering(data) {
    let ordering = []
    this.by.forEach(field => {
      if (!(field in this._customSorting)) {
        let index = this.by.findIndex(selectedField => selectedField === field)
        ordering.push(this._convertBooleanToOrderingFormat(this.desc[index], this._apiAttributes[field]))
      } else if (field in this._customSorting) this._customSorting[field](ordering)
    })
    data[SORT_ARGS_QUERY] = ordering
  }

  _removeSelectedOrdering(field, data) {
    if (SORT_ARGS_QUERY in data && (data[SORT_ARGS_QUERY] !== field || data[SORT_ARGS_QUERY] !== `-${field}`)) {
      let fieldName = SORT_ARGS_QUERY.replace('-', '')
      let fieldNameToRemove = this._orderingFields[fieldName]
      let index = this.by[fieldNameToRemove]
      this.by.splice(index, 1)
      this.desc.splice(index, 1)
    }
  }

  _convertBooleanToOrderingFormat(val, field) {
    if (!val) return `-${field}`
    if (val) return field
    return
  }

  _convertBooleanToMultiOrderingFormat(val, field) {
    if (!val) {
      return field.split(',').map(item => `-${item}`).join(',')
    }
    if (val) {
      return field.split(',').map(item => `${item}`).join(',')
    }
    return
  }

  setCourseCityAndDateSorting(ordering) {
    let index = this.by.findIndex(selectedField => selectedField === `${this._apiAttributes.course__city__name}`)
    ordering.push(this._convertBooleanToOrderingFormat(this.desc[index], `${this._apiAttributes.course__date},${this._apiAttributes.course__city__name}`))
  }
}
