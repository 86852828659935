<template lang="pug">
  .tab-by-course
    v-data-table(v-show="false" :headers="headers")
    app-table(
      :show-select="false"
      :headers="headers"
      :list="statsByCourse"
      :loading="loading"
      hide-default-header
      hide-default-footer
      :sort-by.sync="sortModel.by"
      :sort-desc.sync="sortModel.desc"
      @update:sort-desc="updateSort"
      class="app-table-new v-data-table theme--light"
    )
      template(v-slot:item="{item, index}")
        item(:item="item" :index="index" :key="index")
        total-item(v-if="index === statsByCourse.length - 1" :total="statsTotal")
      template(v-slot:footer)
        .pagination(v-if="pagination")
          .pagination__more
            load-more-button(:loading="loading" :pagination="pagination" @loadMore="loadMore")

    portal(v-if="byCourseTab" to="course-leaders-paginator")
      .d-flex
        pagination(:pagination="pagination" :loading="loading" @change="loadData")
</template>

<script>
import baseTabMixin from "@/app/admin/modules/course_leaders/core/mixins/baseTabMixin";
import { BY_COURSE_TAB_HEADERS} from '../../core/courseLeadersConst';
import { DEFAULT_COUNT_LIST } from '@/util/const';
import errorsMixin from '@/mixins/errors.mixin';

export default {
  inject: ['svc'],

  mixins: [errorsMixin, baseTabMixin],

  data: () => ({
    headers: BY_COURSE_TAB_HEADERS,
    DEFAULT_COUNT_LIST
  }),

  computed: {
    disabledLoadingMore() {
      if (!this.pagination) return true;
      if (this.loading) return true;
      if (!this.pagination.next) return true;
      return false;
    },

    byCourseTab() {
      return this.$route.query.tab === this.COURSE_LEADERS_TABS_LIST.BY_COURSE
    }
  },

  methods: {
    async load() {
      await this.setDefaultPagination()
      await this.loadData()
    },

    async updateSort() {
      await this.updateSorting()
      await this.loadStatsListByCourse({})
    },

    async updateFilters() {
      await this.loadData()
    },

    async loadData() {
      await this.loadStatsListByCourse({})
    },

    async loadMore() {
      if (!this.pagination) return
      await this.onLoadMoreClick()
      await this.loadStatsListByCourse({more: true})
    }
  },

  components: {
    appTable: () => import('@/components/global/GroupedTable.vue'),
    item: () => import('./TableItem.vue'),
    totalItem: () => import('./TotalItem.vue'),
    pagination: () => import('@/components/widget/PaginationWidget.vue'),
    loadMoreButton: () => import('@/components/widget/LoadMoreButton.vue')
  }
}
</script>

<style lang="scss">
.tab-by-course {
  min-height: 100vh;
  padding-bottom: 48px;

  table {
    border-collapse: collapse;
    margin-bottom: 10px;
  }
}

.lm-text {
  text-transform: uppercase;
  color: rgb(0, 0, 0, 0.65);
}
</style>
