export const COURSE_LEADERS_CONST = {
  NAME: 'name',
  ID: 'ID',
  IS_ARCHIVED: 'isArchived',
};

export const FILTERS_ARGS = {
  SEARCH: 'search',
  IS_ARCHIVED: 'is_archived'
};

export const CORRECT_ANSWERS_COUNTS = {
  HAZARD: 25,
  KNOWLEDGE: 12,
  INSIGHT: 28,
  KNOWLEDGE_INSIGHT: 40,

  sum() {
    return this.HAZARD + this.KNOWLEDGE + this.INSIGHT;
  }
};

export const PANEL_STATS_ARGS = {
  COURSE_DATE_FROM: 'course_date_after',
  COURSE_DATE_TO: 'course_date_before',
  COURSE_LEADER_ID: 'course_leader',
  COURSE_CITY_ID: 'city',
  COURSE_SESSION: 'sessions',
  ANSWERS: 'correct_answers',
  HAZARD: 'correct_hazard',
  KNOWLEDGE: 'correct_knowledge',
  INSIGHT: 'correct_insight',
  KNOWLEDGE_INSIGHT: 'correct_knowledge_insight'
};

export const COURSE_LEADERS_TABS_LIST = {
  BY_COURSE: 'by_course',
  BY_LEADERS: 'by_leaders',
  BY_LEADER_ON_COURSE: 'by_leader_on_course',
  LIST: 'list'
};

export const COURSE_LEADERS_CATEGORIES_LIST = [
  {id: 1, name: 'Veilig rijden met het voertuig en reageren in noodsituaties'},
  {id: 2, name: 'Wetgeving'},
  {id: 3, name: 'Voertuigkennis'},
  {id: 4, name: 'Gebruik van de weg'},
  {id: 5, name: 'Verkeerstekens en aanwijzingen'},
  {id: 6, name: 'Verantwoorde verkeersdeelname en milieubewust rijden'},
  {id: 7, name: 'Voorrang en voor laten gaan'},
  {id: 8, name: 'Bijzondere wegen, weggedeelten, weggebruikers en manoeuvres'},
  {id: 9, name: 'Gevaarherkenning'}
];

export const SORT_ARGS_QUERY = 'ordering';

export const LEADERS_QUERY_TAB = 'tab';

export const LEADERS_TAB_HEADERS = [
  { text: 'ID', value: COURSE_LEADERS_CONST.ID, sortable: true, align: 'left' },
  { text: 'Name', value: COURSE_LEADERS_CONST.NAME, sortable: false, align: 'left' },
  { text: 'Archived', value: COURSE_LEADERS_CONST.IS_ARCHIVED, sortable: false, align: 'left' },
  { text: 'Actions', sortable: false, align: 'left' },
];

export const BY_COURSE_TAB_HEADERS = [
  {text: 'Course leader', value: 'leader__name', sortable: false},
  {text: 'Course city+date', value: 'course__city__name', sortable: true},
  {text: 'Success', value: 'success', sortable: true},
  {text: 'Success Een/VDE', value: 'success_days', sortable: false},
  {text: 'Presentie', value: 'presentie', sortable: true},
  {text: 'Presentie Een/VDE', value: 'presentie_days', sortable: false},
  {text: 'Absentie', value: 'absentie', sortable: true},
  {text: '0 mistakes', value: 'zero_mistakes', sortable: true},
  {text: 'Failed', value: 'failed', sortable: true},
  {text: 'Failed Een/VDE', value: 'failed_days', sortable: false},
  {text: 'Passed', value: 'passed', sortable: true},
  {text: 'Passed Een/VDE', value: 'passed_days', sortable: false},
  {text: 'Average rating', value: 'rating', sortable: true}
];

export const BY_LEADERS_TAB_HEADERS = [
  {text: 'Course leader', value: 'leader__name', sortable: true},
  {text: 'Quantity of courses', value: 'course_count', sortable: true},
  {text: 'Images uploaded', value: 'has_course_photo', sortable: true},
  {text: 'Success', value: 'success', sortable: true},
  {text: 'Success Een/VDE', value: 'success_days', sortable: false},
  {text: 'Presentie', value: 'presentie', sortable: true},
  {text: 'Filtered by answers', value: 'answers', sortable: false},
  {text: 'Presentie Een/VDE', value: 'presentie_days', sortable: false},
  {text: 'Absentie', value: 'absentie', sortable: true},
  {text: '0 mistakes', value: 'zero_mistakes', sortable: true},
  {text: 'Failed', value: 'failed', sortable: true},
  {text: 'Failed Een/VDE', value: 'failed_days', sortable: false},
  {text: 'Passed', value: 'passed', sortable: true},
  {text: 'Passed Een/VDE', value: 'passed_days', sortable: false},
  {text: 'Average rating', value: 'rating', sortable: true}
];

export const BY_LEADERS_ON_COURSE_TAB_HEADERS = [
  {text: 'Date', value: 'course_date', sortable: false},
  {text: 'City, name', value: 'course__city__name', sortable: true},
  {text: 'Course leader', value: 'leader__name', sortable: true},
  {text: 'Sessions', value: 'sessions', sortable: false},
  {text: 'Success', value: 'success', sortable: true},
  {text: 'Success Een/VDE', value: 'success_days', sortable: false},
  {text: 'Weight', value: 'weight', sortable: false},
  {text: 'Presentie', value: 'presentie', sortable: true},
  {text: 'Filtered by answers', value: 'answers', sortable: false},
  {text: 'Presentie Een/VDE', value: 'presentie_days', sortable: false},
  {text: 'Absentie', value: 'absentie', sortable: true},
  {text: '0 mistakes', value: 'zero_mistakes', sortable: true},
  {text: 'Failed', value: 'failed', sortable: true},
  {text: 'Passed', value: 'passed', sortable: true},
  {text: 'Mistakes/student', value: 'mistakes_per_student', sortable: true},
  {text: COURSE_LEADERS_CATEGORIES_LIST[0].name, value: 'category_1', class: 'col--category', sortable: false},
  {text: COURSE_LEADERS_CATEGORIES_LIST[1].name, value: 'category_2', class: 'col--category', sortable: false},
  {text: COURSE_LEADERS_CATEGORIES_LIST[2].name, value: 'category_3', class: 'col--category', sortable: false},
  {text: COURSE_LEADERS_CATEGORIES_LIST[3].name, value: 'category_4', class: 'col--category', sortable: false},
  {text: COURSE_LEADERS_CATEGORIES_LIST[4].name, value: 'category_5', class: 'col--category', sortable: false},
  {text: COURSE_LEADERS_CATEGORIES_LIST[5].name, value: 'category_6', class: 'col--category', sortable: false},
  {text: COURSE_LEADERS_CATEGORIES_LIST[6].name, value: 'category_7', class: 'col--category', sortable: false},
  {text: COURSE_LEADERS_CATEGORIES_LIST[7].name, value: 'category_8', class: 'col--category', sortable: false},
  {text: COURSE_LEADERS_CATEGORIES_LIST[8].name, value: 'category_9', class: 'col--category', sortable: false},
  {text: 'Average rating', value: 'rating', sortable: true}
];
