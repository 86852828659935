<template lang="pug">
  courseLeadersList(
    :list="items"
    :headers="headers"
    :loading="loading"
    @reload-list="load"
  )
</template>

<script>
import baseTabMixin from '@/app/admin/modules/course_leaders/core/mixins/baseTabMixin';
import { LEADERS_TAB_HEADERS } from '../../core/courseLeadersConst';
import paginationMixin from '@/mixins/pagination.mixin';
import errorsMixin from '@/mixins/errors.mixin';

export default {
  inject: ['svc'],

  mixins: [paginationMixin, errorsMixin, baseTabMixin],

  data: () => ({
    items: [],
    headers: LEADERS_TAB_HEADERS,
    loading: false
  }),

  methods: {
    load() {
      this.sortedList()
    },

    async sortedList() {
      let list = await this.list()
      this.items = list.sort((a, b) => (a.ID > b.ID) ? 1 : -1);
    },

    async list() {
      try {
        this.loading = true
        let {results} = await this.svc.list(this.$route.query)
        this.items = results;
        return results
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },
  },

  components: {
    courseLeadersList: () => import('../courseLeadersList.vue')
  }
}
</script>
