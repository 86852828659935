<template lang="pug">
  .page-container.course-leaders-page
    portal(to="toolbar-title")
      toolbar-title Course Leaders

    portal(to="toolbar-end")
      app-button(
        v-if="isByLeaderOnCourseTab || isByLeadersTab"
        @click.native="getReport"
        hide-shadow
      ).head-btn Export CSV

    .page-container__block
      course-leaders-filters-panel(:disabled="isListTab" @change:filter="updateFilters" @click:get-report="getReport")

    .tab-wrapper
      v-tabs(color="newPrimary" v-model="tab" background-color="transparent" height="40px" show-arrows).app-tabs
        v-tab(v-if="featureFlags[FEATURE_FLAG.NCRM_6461]" :href="`#${TAB.BY_COURSE}`" @click="switchTab(TAB.BY_COURSE)") By course
        v-tab(:href="`#${TAB.BY_LEADER_ON_COURSE}`" @click="switchTab(TAB.BY_LEADER_ON_COURSE)") By leader on course
        v-tab(:href="`#${TAB.BY_LEADERS}`" @click="switchTab(TAB.BY_LEADERS)") By course leaders
        v-tab(:href="`#${TAB.LIST}`" @click="switchTab(TAB.LIST)") Leaders

        .course-leaders__paginator(:class="{show: (tab === TAB.BY_COURSE || tab === TAB.BY_LEADER_ON_COURSE)}")
          portal-target(name="course-leaders-paginator")

    v-tabs-items(v-model="tab" touchless)
      v-tab-item(v-if="featureFlags[FEATURE_FLAG.NCRM_6461]" :value="TAB.BY_COURSE")
        tab-by-course(:ref="TAB.BY_COURSE" :key="TAB.BY_COURSE")
      v-tab-item(:value="TAB.BY_LEADER_ON_COURSE")
        tab-by-leaders-on-course(:ref="TAB.BY_LEADER_ON_COURSE" :key="TAB.BY_LEADER_ON_COURSE")
      v-tab-item(:value="TAB.BY_LEADERS")
        tab-by-leaders(:ref="TAB.BY_LEADERS" :key="TAB.BY_LEADERS")
      v-tab-item(:value="TAB.LIST")
        tab-leaders-list(:ref="TAB.LIST" :key="TAB.LIST")
</template>

<script>
import Svc from './core/courseLeadersService'
import errorsMixin from '@/mixins/errors.mixin'
import paginationMixin from '@/mixins/pagination.mixin'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

import {
  COURSE_LEADERS_TABS_LIST,
  LEADERS_QUERY_TAB,
  PANEL_STATS_ARGS,
  SORT_ARGS_QUERY
} from './core/courseLeadersConst'

import courseLeadersFiltersPanel from './components/courseLeadersFiltersPanel.vue'

import TabByCourse from './components/tabs/CourseLeadersByCourse.vue'
import TabByLeaders from './components/tabs/CourseLeadersByLeaders.vue'
import TabByLeadersOnCourse from './components/tabs/CourseLeadersOnCourse.vue'
import TabLeadersList from './components/tabs/CourseLeadersListTab.vue'

import appButton from '@/components/global/actions/BaseButton.vue'
import { downloadFile } from '@/util/index.js'

export default {
  mixins: [errorsMixin, paginationMixin, featureFlagsMixin],

  data: () => ({
    svc: new Svc(),
    tab: COURSE_LEADERS_TABS_LIST.BY_LEADER_ON_COURSE,
    TAB: COURSE_LEADERS_TABS_LIST,
    SORT_ARGS_QUERY,
    LEADERS_QUERY_TAB,
    PANEL_STATS_ARGS
  }),

  computed: {
    isByLeaderOnCourseTab() {
      return this.$route.query[LEADERS_QUERY_TAB] === COURSE_LEADERS_TABS_LIST.BY_LEADER_ON_COURSE
    },

    isByLeadersTab() {
      return this.$route.query[LEADERS_QUERY_TAB] === COURSE_LEADERS_TABS_LIST.BY_LEADERS
    },

    isListTab() {
      return this.tab === COURSE_LEADERS_TABS_LIST.LIST
    },

    getToday() {
      return this.$dates().format('YYYY-MM-DD')
    },

    getThirdDaysAgo() {
      return this.$dates().subtract(31, 'days').format('YYYY-MM-DD')
    }
  },

  created() {
    this.preSelectFilters()
    this.initTab()
  },

  async mounted() {
    await this.loadList()
  },

  methods: {
    initTab() {
      if (!this.$route.query[LEADERS_QUERY_TAB]) return this.$router.push({query: {...this.$route.query, [LEADERS_QUERY_TAB]: this.tab}})
      this.tab = this.$route.query[LEADERS_QUERY_TAB]
    },

    async loadList() {
      this.$refs[this.tab].load()
    },

    async switchTab(key) {
      if (this.tab === key) return;
      this.$router.push({
        query: {
          ...this.$route.query,
          ...this.getDefaultPagination(),
          [LEADERS_QUERY_TAB]: key,
          [SORT_ARGS_QUERY]: undefined
        }
      })
      await this.$nextTick()
      this.loadList()
    },

    async updateFilters() {
      this.$refs[this.tab].updateFilters()
    },

    async getReport() {
      try {
        this.loading = true
        let response;

        if (this.isByLeadersTab) response = await this.svc.getCSVReportByLeader(this.$route.query)
        else response = await this.svc.getCSVReportByLeaderOnCourse(this.$route.query)

        downloadFile(response, 'report.csv')
      } catch (err) {
        this.loading = false
        this.processError(err);
      }
    },

    preSelectFilters() {
      if (!this.$route.query[PANEL_STATS_ARGS.COURSE_DATE_TO]) this.setQuery({[PANEL_STATS_ARGS.COURSE_DATE_TO]: this.getToday})
      if (!this.$route.query[PANEL_STATS_ARGS.COURSE_DATE_FROM]) this.setQuery({[PANEL_STATS_ARGS.COURSE_DATE_FROM]: this.getThirdDaysAgo})
    },

    async setQuery(query) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ...query}})
    }
  },

  provide: function() {
    return {
      svc: this.svc
    }
  },

  components: {
    appButton,
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    courseLeadersFiltersPanel,
    TabByCourse,
    TabByLeaders,
    TabByLeadersOnCourse,
    TabLeadersList
  },
}
</script>

<style lang="scss" scoped>
.tab-wrapper {
  border-bottom: 1px solid #f4f7f9;
  border-top: 1px solid #f4f7f9;
  width: 100%;
  background-color: white;
}
</style>

<style lang="scss">
@import "@/assets/scss/commonNew";
@import "@/assets/scss/tabsNew";

.course-leaders__paginator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  margin-left: auto;
  opacity: 0;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: all;
  }
}
</style>
