import Svc from '../courseLeadersService';
import errorsMixin from '@/mixins/errors.mixin';
import PaginationModel from '@/models/paginationModel';
import paginationMixin from '@/mixins/pagination.mixin';
import CourseLeadersSortModel from '../courseLeadersStatsSortModel';
import { LEADERS_QUERY_TAB, COURSE_LEADERS_TABS_LIST } from '../courseLeadersConst';

export default {
  mixins: [errorsMixin, paginationMixin],

  data: () => ({
    svc: new Svc(),
    loading: false,
    statsByCourse: [],
    statsByLeader: [],
    statsByLeaderOnCourse: [],
    statsTotal: {},
    pagination: {},
    count: 0,
    sortModel: null,
    LEADERS_QUERY_TAB,
    COURSE_LEADERS_TABS_LIST
  }),

  created() {
    this.initSortModel();
  },

  methods: {
    async loadStatsListByLeaders() {
      try {
        if (this.loading) return;
        this.loading = true;

        let res = await this.svc.statsListByLeader(this.$route.query);
        this.count = res.pagination.count;
        this.statsByLeader = res.results;
        this.statsTotal = res.totals;
        await this.$nextTick();
        return res;
      } catch (error) {
        console.log(error);
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM});
      } finally {
        this.loading = false;
      }
    },

    async loadStatsListByLeaderOnCourse({more = false}) {
      try {
        if (this.loading) return;
        this.loading = true;

        let res = await this.svc.statsListByLeaderOnCourse(this.$route.query);
        if (more) this.statsByLeaderOnCourse.push(...res.results);
        else this.statsByLeaderOnCourse = res.results;
        this.statsTotal = res.totals;
        await this.$nextTick();
        this.pagination = new PaginationModel(this.setPaginationParams(res.pagination));
        this.count = res.pagination.count;
        return res;
      } catch (error) {
        console.log(error);
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM});
      } finally {
        this.loading = false;
      }
    },

    async loadStatsListByCourse({more = false}) {
      try {
        if (this.loading) return;
        this.loading = true;

        let res = await this.svc.statsListByCourse(this.$route.query);
        if (more) this.statsByCourse.push(...res.results);
        else this.statsByCourse = res.results;
        this.statsTotal = res.totals;
        await this.$nextTick();
        this.pagination = new PaginationModel(this.setPaginationParams(res.pagination));
        this.count = res.pagination.count;
        return res;
      } catch (error) {
        console.log(error);
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM});
      } finally {
        this.loading = false;
      }
    },

    async paginationChange(page) {
      if (this.loading) return;
      await this.$router.replace({query: {...this.$route.query, page: page}});
      this.loadData();
    },

    initSortModel() {
      this.sortModel = new CourseLeadersSortModel(this.$route.query);
    },

    async updateSorting(ordering) {
      let data = this.sortModel.getApiData(this.$route.query);
      if (data.ordering.length) ordering = data.ordering;
      this.$emit('change:sorting', ordering);

      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ordering: ordering}});
    },
  },
};
