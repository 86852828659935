<template lang="pug">
  .tab-by-leaders
    v-data-table(v-show="false" :headers="headers" :list="statsByLeader")
    app-table(
      :show-select="false"
      :headers="headers"
      :list="statsByLeader"
      :loading="loading"
      hide-default-header
      hide-default-footer
      :sort-by.sync="sortModel.by"
      :sort-desc.sync="sortModel.desc"
      @update:sort-desc="updateSort"
      class="app-table-new v-data-table theme--light"
    )
      template(v-slot:item="{item, index}")
        item(:item="item" :index="index" :key="index" :total="statsTotal")
        total-item(v-if="index === statsByLeader.length - 1" :total="statsTotal")
</template>

<script>
import baseTabMixin from "@/app/admin/modules/course_leaders/core/mixins/baseTabMixin";
import { BY_LEADERS_TAB_HEADERS } from '../../core/courseLeadersConst';
import errorsMixin from '@/mixins/errors.mixin';

export default {
  inject: ['svc'],

  mixins: [errorsMixin, baseTabMixin],

  data: () => ({
    headers: BY_LEADERS_TAB_HEADERS
  }),

  methods: {
    async load() {
      await this.loadData()
    },

    async updateSort() {
      await this.updateSorting()
      await this.loadStatsListByLeaders()
    },

    async loadData() {
      await this.loadStatsListByLeaders()
    },

    async updateFilters() {
      await this.loadData()
    }
  },

  components: {
    appTable: () => import('@/components/global/GroupedTable.vue'),
    item: () => import('./TableItem.vue'),
    totalItem: () => import('./TotalItem.vue'),
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue')
  }
}
</script>

<style lang="scss">
.tab-by-leaders {
  min-height: 100vh;
  padding-bottom: 48px;

  table {
    border-collapse: collapse;
  }
}
</style>
